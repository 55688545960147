import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    height: [44, null, 88],
    //height: [44, null, 110],
    background: 'transparent',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    paddingLeft: ['1rem',null,'2rem']
  })),
  topScrolled: css(mq({
    //height: [44, null, 74],
    background: "white"
  })),
  navBox: css(mq({
    position:'fixed',
    right: '60px'
  })),
  logo: css(mq({

  })),
  logoImg: css(mq({
    display: 'block',
    height: [60, null, 90],
    transition: 'all 0.3s',
    marginTop: ["2rem", null, "2rem"],
  })),
  logoScrolled: css(mq({})),
  logoImgScrolled : mq({
    height: [40, null, 70],
    marginTop: ["0rem", null, "0rem"],
  })
}